<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      Accordion: {
        count: 0,
        active: null
      }
    };
  },
  provide() {
    return { Accordion: this.Accordion };
  }
};
</script>

<style lang="scss" scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
  color: black;
  border-style: solid;  /* was solid ..  set to none for no border*/
  border-color: rgb(32, 159, 218);


  &__item:last-child {
    border-bottom: none;
  }
}
</style>
